import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
	
	username:any;
	admin=false;
	loc=false;
	

  constructor() {
  }

  ngOnInit() {
	  
	  this.username=localStorage.getItem('username');
	  
	  
	  //alert(this.username);
	  if(this.username=='admin')
	  {
		 this.admin=true; 
	  }
	  else{
		 this.loc=true; 		  
	  }
	  
	  
  }

}
